import Form from "./Components/Form.js"
import Code from "./Components/Qr-code.js"
import Home from "./Components/Home.js"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PrivateRoutes from "./Components/Privateroutes.js"
function App() {
	

	return (
		<div className="wholeApp">
			<BrowserRouter>
				<Routes>
					<Route element={<PrivateRoutes />}>
						<Route element={<Code />} path="/code"/>	
					</Route>
					<Route element={<Home />} path="/"/>
					<Route element={<Form />} path="/form"/>
				</Routes>
			</BrowserRouter>
		</div>
		
	);
}

export default App;
