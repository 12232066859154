import ReactLoading from "react-loading"
import logo from '../logo.png'
import "../styles/Loading.css"

function Loading(){
	return(
		<div className="enseriotio">
			<div className="enseriotioHeader">
				<img src={logo}></img>
			</div>
			<div className="loading">
				<ReactLoading height={100} width={50} type="spin" />
			</div>
		</div>
	)	
}

export default Loading
