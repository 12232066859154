import QRCode from 'qrcode.react'
import { useEffect, useState } from "react"
import logo from '../logo.png'
import '../styles/Code.css'

function Code() {
	
	const [id, setId] = useState("")
	useEffect(() => {
		setId(localStorage.getItem('Id'))
	}, [])

	return (
		<div className="Code">
			
			<div className="codeLogo">
				<img src={logo}></img>
			</div>
			<QRCode value={id} bgColor="pink" renderAs="canvas" />
			<p>Guarda este código para enseñarlo en cualquier mesa y pagar.</p>
		</div>
	)
}

export default Code
