import '../App.css';
import logo from '../logo.png'
import { useState, useEffect } from "react"
import { db } from "./Firebase.js"
import { collection, addDoc } from "firebase/firestore"
import Code from "./Qr-code.js"
import { Link, useNavigate } from "react-router-dom"
import Loading from "./Loading.js"
import axios from 'axios'

const model = {
	name: "",
	clase: "primeroA",
	anon: false,

	toName: "",
	toClase: "primeroA",
	num: 1,

	msg: "",

	date: new Date(),
	paid: false,
	done: false,
	lastModified: "",
}

function Form() {
	const [tabs, setTabs] = useState([0])
	const [currentTab, setCurrentTab] = useState(0)
	const [requestArray, setRequestArray] = useState([model])
	const navigate = useNavigate()
	const [reqObject, setReqObject] = useState({
		name: "",
		clase: "primeroA",
		anon: false,

		toName: "",
		toClase: "primeroA",
		num: 1,

		msg: "",

		date: new Date(),
		paid: false,
		done: false,
		lastModified: "",
	});
	const [writer, setWriter] = useState("")
	const [writerCourse, setWriterCourse] = useState("primeroA")
	const [receiver, setReceiver] = useState("")
	const [receiverCourse, setReceiverCourse] = useState("primeroA")
	const [anon, setAnon] = useState(false)
	const [numFlores, setNumFlores] = useState(1)
	const [msg, setMsg] = useState("")
	const [completed, setCompleted] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [tabsFull, setTabsFull] = useState(false)
	const [changeTab, setChangeTab] = useState(false)
	const [oneTab, setOneTab] = useState(true)
	const [ip, setIp] = useState("")

	const appendRequest = (index, object) => {
		const newArray = [...requestArray]
		newArray[index] = object

		setRequestArray(newArray)
	};

	const handleSubmit = async (e) => {
			e.preventDefault()
			setIsLoading(true)
			let realId = ""
	
			await requestArray.map(async (obj,  index) => {
				try {
					const upload = await addDoc(collection(db, "Requests"), obj)
					realId = realId + upload.id + ','
				} catch (e) {
					console.error(e)
				}
			})
	
			setTimeout(() => {
				localStorage.setItem('Id', realId)
				navigate("/code")
			}, 3000)
		}

	const clearStates = () => {
		setWriter(requestArray[currentTab].name)
		setWriterCourse(requestArray[currentTab].clase)
		setReceiver(requestArray[currentTab].toName)
		setReceiverCourse(requestArray[currentTab].toClase)
		setAnon(requestArray[currentTab].anon)
		setNumFlores(requestArray[currentTab].num)
		setMsg(requestArray[currentTab].msg)
	};

	const handleTab = (e) => {
		setCurrentTab(Number(e.target.textContent)-1)
	};

	const addTab = (e) => {
		setTabs((prevState) => [...prevState, 0])
		setRequestArray((prevState) => [...prevState, model])
	};

	const removeTab = (e) => {
		setTabs((prevState) => {
			let newArray = prevState
			let filter = newArray.filter((element, index) => {
					return index != currentTab
			})
			return filter
		})
		setRequestArray((prevState) => {
			let newArray = prevState
			let filter = newArray.filter((element, index) => index != currentTab)
			return filter
		})
		setCurrentTab(0)
		setChangeTab((prevState) => !prevState)
	}

	const checkCompleted = () => {
		requestArray.map((obj) => {
			const { name, clase, toName, toClase, anon, num, msg } = obj
			if(name == "" || toName == "" || msg == ""){
				setCompleted(false)
			} else {
				setCompleted(true)
			}
		})
	};

	const checkOnly = () => {
		if(tabs.length <= 1){
			setOneTab(true)
		} else {
			setOneTab(false)
		}
	}

	const checkTabs = () => {
		if(tabs.length >= 10){
			setTabsFull(true)
		} else {
			setTabsFull(false)
		}
	}

	const getData = async () => {
		const res = await axios.get('https://geolocation-db.com/json/')
		setIp(res.data.IPv4)
	}

	useEffect(() => {
		appendRequest(currentTab, reqObject)
	}, [reqObject]);


	useEffect(() => {
		clearStates()
		setReqObject(requestArray[currentTab])
		
	}, [currentTab, changeTab]);

	useEffect(() => {	
		checkCompleted()
		checkTabs()
		checkOnly()
		const interval = setInterval(() => {
			checkCompleted()
			checkTabs()
			checkOnly()
		}, 250)
		return () => clearInterval(interval) 
	}, [checkCompleted, checkTabs, checkOnly])
	
	useEffect(() => {
		setReqObject((prevState) => ({
			...prevState,
			name: writer,
			clase: writerCourse,
			anon: anon,

			toName: receiver,
			toClase: receiverCourse, 
			num: numFlores,

			msg: msg,
			lastModified: ip
		}))	

	}, [writer, writerCourse, receiver, receiverCourse, anon, numFlores, msg])

	useEffect(() => {
		getData()
	}, [])
	

	return isLoading ? <Loading /> : (
		<div className="App">
			<div className="header">
				<img src={logo}></img>
			</div>
			<div className="operation-buttons">
				<button className="op" disabled={tabsFull ? true : false} onClick={addTab}>+</button>
				<button className="op" disabled={oneTab ? true : false} onClick={removeTab}>-</button>
			</div>
			<div className="tabs">
				{
					tabs?.map((object, index) => (
						<button className={index == currentTab ? "selected" : ""} onClick={handleTab}>{index+1}</button>
					))		
				}
			</div>
			<form>
				<div className= "qs">
					<div className="container">
						<p>De:</p>
						<input type="text" value={writer} placeholder="Escribe aquí..." id="name" onChange={(e) => setWriter(e.target.value)}></input>	
						<p>de</p>
						<select name="tuclase" value={writerCourse} id="clase" onChange={(e) => setWriterCourse(e.target.value)}>
							<option value='profesor'>Profesor</option>
							<option value='primeroA'>1ºA ESO</option>
							<option value='primeroB'>1ºB ESO</option>
							<option value='primeroC'>1ºC ESO</option>
							<option value='primeroD'>1ºD ESO</option>
							<option value='primeroE'>1ºE ESO</option>
							<option value='primeroF'>1ºF ESO</option>
							<option value='segundoA'>2ºA ESO</option>
							<option value='segundoB'>2ºB ESO</option>
							<option value='segundoC'>2ºC ESO</option>
							<option value='segundoD'>2ºD ESO</option>
							<option value='segundoE'>2ºE ESO</option>
							<option value='segundoF'>2ºF ESO</option>
							<option value='terceroA'>3ºA ESO</option>
							<option value='terceroB'>3ºB ESO</option>
							<option value='terceroC'>3ºC ESO</option>
							<option value='terceroD'>3ºD ESO</option>
							<option value='terceroE'>3ºE ESO</option>
							<option value='cuartoA'>4ºA ESO</option>
							<option value='cuartoB'>4ºB ESO</option>
							<option value='cuartoC'>4ºC ESO</option>
							<option value='cuartoD'>4ºD ESO</option>
							<option value='cuartoE'>4ºE ESO</option>
							<option value='primerobachA'>1ºA BACH</option>
							<option value='primerobachB'>1ºB BACH</option>
							<option value='primerobachC'>1ºC BACH</option>
							<option value='primerobachD'>1ºD BACH</option>
							<option value='segundobachA'>2ºA BACH</option>
							<option value='segundobachB'>2ºB BACH</option>
							<option value='segundobachC'>2ºC BACH</option>
							<option value='segundobachD'>2ºD BACH</option>
							<option value='cicloA'>Ciclo A</option>
							<option value='cicloB'>Ciclo B</option>
							<option value='gradoprimero'>1º grado</option>
							<option value='segundobachD'>2º grado</option>

						</select>
					</div>
					<div className="tuPutaMadre">
						<label><input type="checkbox" checked={anon} id="anon" onChange={(e) => setAnon(e.target.checked)}></input>Anónimo</label>
					</div>
					<div className="container">
						<p>Para:</p>
						<input type="text" placeholder="Escribe aquí..."  value={receiver} id="toName" onChange={(e) => setReceiver(e.target.value)}></input>
						<p>de</p>
						<select name="suclase"  value={receiverCourse} id="toClase" onChange={(e) => setReceiverCourse(e.target.value)}>	
							<option value='profesor'>Profesor</option>
							<option value='primeroA'>1ºA ESO</option>
							<option value='primeroB'>1ºB ESO</option>
							<option value='primeroC'>1ºC ESO</option>
							<option value='primeroD'>1ºD ESO</option>
							<option value='primeroE'>1ºE ESO</option>
							<option value='primeroF'>1ºF ESO</option>
							<option value='segundoA'>2ºA ESO</option>
							<option value='segundoB'>2ºB ESO</option>
							<option value='segundoC'>2ºC ESO</option>
							<option value='segundoD'>2ºD ESO</option>
							<option value='segundoE'>2ºE ESO</option>
							<option value='segundoF'>2ºF ESO</option>
							<option value='terceroA'>3ºA ESO</option>
							<option value='terceroB'>3ºB ESO</option>
							<option value='terceroC'>3ºC ESO</option>
							<option value='terceroD'>3ºD ESO</option>
							<option value='terceroE'>3ºE ESO</option>
							<option value='cuartoA'>4ºA ESO</option>
							<option value='cuartoB'>4ºB ESO</option>
							<option value='cuartoC'>4ºC ESO</option>
							<option value='cuartoD'>4ºD ESO</option>
							<option value='cuartoE'>4ºE ESO</option>
							<option value='primerobachA'>1ºA BACH</option>
							<option value='primerobachB'>1ºB BACH</option>
							<option value='primerobachC'>1ºC BACH</option>
							<option value='primerobachD'>1ºD BACH</option>
							<option value='segundobachA'>2ºA BACH</option>
							<option value='segundobachB'>2ºB BACH</option>
							<option value='segundobachC'>2ºC BACH</option>
							<option value='segundobachD'>2ºD BACH</option>
							<option value='cicloA'>Ciclo A</option>
							<option value='cicloB'>Ciclo B</option>
							<option value='gradoprimero'>1º grado</option>
							<option value='segundobachD'>2º grado</option>
						</select>
					</div>
					<div className="stepper">
						<label>Número de flores</label>
						<select name="numFlores"  value={numFlores} id="num" onChange={(e) => setNumFlores(Number(e.target.value))}>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9</option>
							<option value="10">10</option>
						</select>
					</div>
					<textarea placeholder = "Escribe aqui tu mensaje" id="msg" value={msg}  onChange={(e) => setMsg(e.target.value)}></textarea>
				</div>
				<button className="send" onClick={handleSubmit} disabled={!completed}>Enviar</button>	
			</form>
    		</div>
	)
}

export default Form
