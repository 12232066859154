import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
	apiKey: "AIzaSyBavf1Iy1-s0ipFsUrxFIczlnlYvkYoNHs",
	authDomain: "sanvalentin2023-f7524.firebaseapp.com",
	projectId: "sanvalentin2023-f7524",
	storageBucket: "sanvalentin2023-f7524.appspot.com",
	messagingSenderId: "775615027009",
	appId: "1:775615027009:web:9bf62055bde81e132333c0"
}


const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
