import { Outlet, Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

function checkAuth(){
	return(localStorage.getItem('Id'))
}

const PrivateRoutes = () => {
	const [auth, setAuth] = useState(false)

	useState(() => {
		setAuth(checkAuth())
	})
	return(
		auth ? <Outlet/> : <Navigate to="/"/> 
	)
}

export default PrivateRoutes
