import { Link } from 'react-router-dom'
import logo from '../logo.png'
import '../styles/Home.css'
function Home() {
	return(
		<div className="Home">
			<div className="homeLogo">
				<img src={logo}></img>
			</div>
			<h1>Bienvenido a la página web de San Valentín 2023 del IES Alquibla.</h1> 
			<p>Para enviar una flor a alguien, deberás rellenar el siguiente formulario. Se te dará un código QR que podrás enseñar para pagar en las mesas disponibles en los recreos.</p>
			<b>Aunque se active la opcion de anónimo, es necesario poner el nombre completo para gestionar el pedido. El nombre no se compartirá con nadie, y solo será utilizado en el momento del pago.</b>
			<p>Si quieres enviar flores a personas diferentes, te recomendamos que lo hagas de una sola vez utilizando el sistema de pestañas mediante el botón +.</p>
			<h3>¡Feliz San Valentín!</h3> 
			<Link to="/form"><label className="sendForm">Empezar</label></Link>	
		</div>
	)
}

export default Home
